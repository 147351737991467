import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import api from "../services/api";
import { jsonToPrettyStr } from "../utils/jsonUtils";

const ChangePassword = () => {
  const intitialNavigate = localStorage.getItem("token") ? null : "/login";
  const [oldPass, setOldPass] = useState("");
  const [password, setPassword] = useState("");
  const [retypePass, setRetypePass] = useState("");
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [navigateTo, setNavigateTo] = useState(intitialNavigate);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== retypePass) {
      setError("New password and retyped password do not match.");
      return;
    }

    setSubmitting(true);
    api
      .put("/change-password/", {
        old_password: oldPass,
        new_password: password,
      })
      .then((res) => {
        alert(res.data.detail);
        setNavigateTo("/");
      })
      .catch((err) => {
        err.response
          ? setError(jsonToPrettyStr(err.response.data))
          : alert(err.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-xl p-4 m-4 rounded-lg flex flex-col items-center sm:m-8 lg:max-w-[50%] lg:mx-auto lg:my-16"
    >
      {navigateTo && <Navigate to={navigateTo} />}
      <h1 className="text-center text-2xl sm:text-4xl">Change Password</h1>
      <div className="relative z-0 my-3 w-full">
        <input
          type="password"
          id="oldPass"
          className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-secondary peer"
          placeholder=" "
          required={true}
          value={oldPass}
          onChange={(e) => setOldPass(e.target.value)}
        />
        <label
          htmlFor="oldPass"
          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-secondary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
        >
          Old Password
        </label>
      </div>
      <div className="relative z-0 my-3 w-full">
        <input
          type="password"
          id="password"
          className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-secondary peer"
          placeholder=" "
          required={true}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label
          htmlFor="password"
          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-secondary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
        >
          New Password
        </label>
      </div>
      <div className="relative z-0 my-3 w-full">
        <input
          type="password"
          id="retypePass"
          className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-secondary peer"
          placeholder=" "
          required={true}
          value={retypePass}
          onChange={(e) => setRetypePass(e.target.value)}
        />
        <label
          htmlFor="retypePass"
          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-secondary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
        >
          Retype New Password
        </label>
      </div>
      {error && <p className="bg-red-500 p-2 rounded-lg my-2">{error}</p>}
      <button
        type="submit"
        className="bg-primary hover:bg-secondary text-white font-bold p-4 rounded-xl disabled:bg-gray-400"
        disabled={submitting}
      >
        {submitting ? <BeatLoader className="m-auto" /> : "Submit"}
      </button>
    </form>
  );
};

export default ChangePassword;
