import axios from "axios";
import { jsonToPrettyStr } from "../utils/jsonUtils";

export const apiErrAlert = (err) => {
  if (err.response) {
    if (
      err.response.data.detail &&
      err.response.data.detail === "Invalid token."
    ) {
      localStorage.removeItem("token");
      window.location.reload();
    } else {
      alert(jsonToPrettyStr(err.response.data));
    }
  } else {
    alert(err.message);
  }
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Use the base URL from .env file
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    } else {
      delete config.headers.Authorization;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
