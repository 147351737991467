import React, { useState, useEffect, useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";
import api, { apiErrAlert } from "../services/api";
import { PuffLoader } from "react-spinners";

import NewsCard from "../components/NewsCard";
import NumberNav from "../components/NumberNav";

import filler from "../assets/QAO_Logo.png";
import { orderImages } from "../utils/imageOrderUtils";

const News = ({ currentUser }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [posts, setPosts] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const getPage = useCallback(() => {
    return parseInt(searchParams.get("page")) || 1;
  }, [searchParams]);
  const setPage = (page) => {
    searchParams.set("page", page);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/posts/?page=${getPage()}`)
      .then((res) => {
        setPosts(res.data.results);
        setTotalPages(res.data.total_pages);
      })
      .catch((err) => {
        apiErrAlert(err);
      })
      .finally(() => setIsLoading(false));
  }, [getPage]);

  return (
    <div className="p-4 sm:p-8">
      {currentUser !== "" && (
        <div className="w-full flex justify-center mb-2">
          <button className="p-3 text-xl bg-primary hover:bg-secondary text-white rounded-full">
            <Link to={"/post/edit/new"}>Create new post</Link>
          </button>
        </div>
      )}
      {/* News Section */}
      <div className="pb-4 sm:pb-8">
        <div className="flex items-center gap-2 sm:gap-4">
          <h1 className="text-4xl">Latest News</h1>
          <hr className="border-secondary border-2 rounded-md flex-grow" />
        </div>
      </div>
      <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
        {isLoading ? (
          <PuffLoader color="#014421" className="m-auto col-span-3" size={32} />
        ) : (
          posts.map((post) => {
            return (
              <NewsCard
                key={post.id}
                targetPage={`/post/${post.id}`}
                bannerImg={
                  post.images[0]
                    ? orderImages(post.images, post.image_order)[0].image
                    : filler
                }
                title={post.title}
                author={post.authors
                  .map((author) => author.full_name)
                  .join(", ")}
                content={post.content}
                date={post.created}
                is_published={
                  currentUser === post.owner ? post.is_published : null
                }
              />
            );
          })
        )}
      </div>
      <NumberNav
        currentPage={getPage()}
        totalPages={totalPages}
        setPageFn={setPage}
      />
    </div>
  );
};

export default News;
