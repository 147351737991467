import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react";
import api from "./services/api";

import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import News from "./pages/News";
import Events from "./pages/Events";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Post from "./pages/Post";
import Event from "./pages/Event";
import Page404 from "./pages/Page404";
import LogIn from "./pages/LogIn";
import EditEvent from "./pages/EditEvent";
import EditPost from "./pages/EditPost";
import ChangePassword from "./pages/ChangePassword";

function App() {
  const [currentUser, setCurrentUser] = useState("");
  const getCurrentUser = () => {
    api
      .get("/current-user")
      .then((res) => {
        setCurrentUser(res.data.username);
      })
      .catch((err) => {
        setCurrentUser("");
      });
  };

  return (
    <div className="App bg-gray-100">
      <BrowserRouter>
        <NavBar currentUser={currentUser} getCurrentUser={getCurrentUser} />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/news" element={<News currentUser={currentUser} />} />
          <Route
            path="/events"
            element={<Events currentUser={currentUser} />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />

          <Route
            path="/post/:id"
            element={<Post currentUser={currentUser} />}
          />
          <Route
            path="/event/:id"
            element={<Event currentUser={currentUser} />}
          />
          <Route
            path="/post/edit/:id"
            element={<EditPost currentUser={currentUser} />}
          />
          <Route
            path="/event/edit/:id"
            element={<EditEvent currentUser={currentUser} />}
          />

          <Route
            path="/login"
            element={<LogIn getCurrentUser={getCurrentUser} />}
          />
          <Route path="/change-password" element={<ChangePassword />} />

          {/* 404 page */}
          <Route path="*" element={<Page404 />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
