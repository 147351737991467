import React, { useEffect, useState } from "react";
import { LuMapPin, LuPhone, LuMail } from "react-icons/lu";
import { FaFacebook, FaExternalLinkAlt } from "react-icons/fa";
import { IoIosArrowDropupCircle } from "react-icons/io";
// import { FaXTwitter } from "react-icons/fa6";

import QaoLogo from "../assets/UPMin_QAO.png";
import UpMinLogo from "../assets/UPMin_Logo.png";

const Footer = () => {
  const [showBTTop, setShowBTTop] = useState(false);

  useEffect(() => {
    const handleBackToTopVisibility = () => {
      window.scrollY > 500 ? setShowBTTop(true) : setShowBTTop(false);
    };
    window.addEventListener("scroll", handleBackToTopVisibility);
    return () => {
      window.removeEventListener("scroll", handleBackToTopVisibility);
    };
  }, []);

  // back to top function
  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <IoIosArrowDropupCircle
        size={48}
        className={
          showBTTop
            ? "fixed bottom-4 right-4 text-up-yellow shadow-2xl rounded-full bg-primary duration-300"
            : "fixed -bottom-16 right-4 text-up-yellow shadow-2xl rounded-full bg-primary duration-300"
        }
        onClick={backToTop}
      />
      <div className="flex flex-col gap-4 justify-evenly sm:grid sm:grid-cols-[30%_auto] lg:flex lg:flex-row px-4 py-4 lg:px-16 lg:py-8 bg-primary text-white text-xl">
        <div className="flex gap-4 items-center sm:flex-col justify-evenly sm:row-span-3">
          <img
            src={UpMinLogo}
            alt="UP Mindanao Logo"
            className="h-20 w-20 sm:h-32 sm:w-32 object-contain"
          />
          <img
            src={QaoLogo}
            alt="Quality Assurance Office Logo"
            className="h-20 w-20 sm:h-32 sm:w-32 object-contain"
          />
        </div>
        <div className="flex-col">
          <h1 className="text-3xl mb-2">Quality Assurance Office</h1>
          <div className="flex gap-2 items-center">
            <LuMapPin className="min-w-5" />
            <p>
              Administration Building, Mintal, Davao City, 8022, Philippines
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <LuPhone className="min-w-5" />
            <p>(+63 82)2930863 and (+63 82)2930185</p>
          </div>
          <div className="flex gap-2 items-center">
            <LuMail className="min-w-5" />
            <a href="mailto:qao.upmindanao@up.edu.ph">
              <p>qao.upmindanao@up.edu.ph</p>
            </a>
          </div>
          <div className="flex gap-4 p-2 text-4xl">
            <a
              href="https://www.facebook.com/qao.upmin"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook className="hover:text-blue-600 hover:cursor-pointer" />
            </a>
            {/* <FaXTwitter className="hover:text-black hover:cursor-pointer" /> */}
          </div>
        </div>
        <hr className="lg:hidden" />
        <div className="flex flex-col text-sm">
          <h1 className="text-3xl mb-2">Related Links</h1>
          <div className="flex flex-col sm:flex-row gap-4">
            <ul>
              <li className="flex items-center gap-2">
                University of the Philippines Mindanao
                <a
                  href="https://prod.upmin.edu.ph/"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-secondary block"
                >
                  <FaExternalLinkAlt />
                </a>
                <a
                  href="https://www.facebook.com/UPMindanao"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-blue-600 block"
                >
                  <FaFacebook />
                </a>
              </li>

              <ul className="ml-4">
                <li className="flex items-center gap-2">
                  College of Humanities and Social Sciences
                  <a
                    href="https://www.facebook.com/chss.upmin"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-600 block"
                  >
                    <FaFacebook />
                  </a>
                </li>
                <ul className="ml-4">
                  <li className="flex items-center gap-2">
                    Department of Humanities
                    <a
                      href="https://www.facebook.com/HumanitiesUPMin"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-blue-600 block"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                  <li className="flex items-center gap-2">
                    Department of Architecture
                    <a
                      href="https://upmin-architecture.up.edu.ph/"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-secondary block"
                    >
                      <FaExternalLinkAlt />
                    </a>
                    <a
                      href="https://www.facebook.com/UPMindanaoDA"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-blue-600 block"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                  <li className="flex items-center gap-2">
                    Department of Human Kinetics
                    <a
                      href="https://www.facebook.com/upmindanaodhk/"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-blue-600 block"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                  <li className="flex items-center gap-2">
                    Department of Social Sciences
                    <a
                      href="https://www.facebook.com/profile.php?id=100057482857818"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-blue-600 block"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                </ul>
              </ul>

              <ul className="ml-4">
                <li className="flex items-center gap-2">
                  College of Science and Mathematics
                  <a
                    href="https://www.facebook.com/csmupmin/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-600 block"
                  >
                    <FaFacebook />
                  </a>
                </li>
                <ul className="ml-4">
                  <li className="flex items-center gap-2">
                    Department of Mathematics, Physics, and Computer Science
                    <a
                      href="https://dmpcs.upmin.edu.ph/"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-secondary block"
                    >
                      <FaExternalLinkAlt />
                    </a>
                    <a
                      href="https://www.facebook.com/OfficialDMPCS"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-blue-600 block"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                  <li className="flex items-center gap-2">
                    Department of Food Science and Chemistry
                    <a
                      href="https://dfsc.upmin.edu.ph/"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-secondary block"
                    >
                      <FaExternalLinkAlt />
                    </a>
                    <a
                      href="https://www.facebook.com/dfscupmindanao"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-blue-600 block"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                  <li className="flex items-center gap-2">
                    Department of Biological Sciences and Environmental Studies
                    <a
                      href="https://dbses.upmin.edu.ph/"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-secondary block"
                    >
                      <FaExternalLinkAlt />
                    </a>
                    <a
                      href="https://www.facebook.com/dbsesupmindanao/"
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-blue-600 block"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                </ul>
              </ul>

              <ul className="ml-4">
                <li className="flex items-center gap-2">
                  School of Management
                  <a
                    href="https://www.facebook.com/upschoolofmanagement"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-600 block"
                  >
                    <FaFacebook />
                  </a>
                </li>
              </ul>
            </ul>
            <ul>
              <li>CU Quality Assurance Office</li>
              <ul className="ml-4">
                <li className="flex items-center gap-2">
                  UP System
                  <a
                    href="https://qa.up.edu.ph/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-secondary block"
                  >
                    <FaExternalLinkAlt />
                  </a>
                  <a
                    href="https://www.facebook.com/up.qualityassurance"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-600 block"
                  >
                    <FaFacebook />
                  </a>
                </li>
                <li className="flex items-center gap-2">
                  UP Diliman
                  <a
                    href="https://qa.upd.edu.ph/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-secondary block"
                  >
                    <FaExternalLinkAlt />
                  </a>
                  <a
                    href="https://www.facebook.com/QAODiliman"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-600 block"
                  >
                    <FaFacebook />
                  </a>
                </li>
                <li className="flex items-center gap-2">
                  UP Los Baños
                  <a
                    href="https://qao.uplb.edu.ph/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-secondary block"
                  >
                    <FaExternalLinkAlt />
                  </a>
                  <a
                    href="https://www.facebook.com/UPLBQAO/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-600 block"
                  >
                    <FaFacebook />
                  </a>
                </li>
                <li className="flex items-center gap-2">UP Manila</li>
                <li className="flex items-center gap-2">
                  UP Visayas
                  <a
                    href="https://www.upv.edu.ph/upv-qa/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-secondary block"
                  >
                    <FaExternalLinkAlt />
                  </a>
                </li>
                <li className="flex items-center gap-2">
                  UP Open University
                  <a
                    href="https://ovcaa.upou.edu.ph/quality-assurance/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-secondary block"
                  >
                    <FaExternalLinkAlt />
                  </a>
                </li>
                <li className="flex items-center gap-2">
                  UP Baguio
                  <a
                    href="https://www.upb.edu.ph/quality-assurance/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-secondary block"
                  >
                    <FaExternalLinkAlt />
                  </a>
                </li>
                <li className="flex items-center gap-2">
                  UP Cebu
                  <a
                    href="https://upcebu.edu.ph/quality-assurance-office/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-secondary block"
                  >
                    <FaExternalLinkAlt />
                  </a>
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>

      <p className="text-center text-xs bg-primary text-white pb-4">
        Copyright All rights reserved © 2024
      </p>
    </div>
  );
};

export default Footer;
