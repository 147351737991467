import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api, { apiErrAlert } from "../services/api";
import { PuffLoader } from "react-spinners";
import { dateToString } from "../utils/dateUtils";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import Page404 from "./Page404";
import PostContentComponent from "../components/PostContentComponent";
import { FaEdit } from "react-icons/fa";
import { orderImages } from "../utils/imageOrderUtils";

const PostContent = ({ postData, currentUser }) => {
  const stringDateCreated = dateToString(postData.created);
  const stringDateLastEdited = dateToString(postData.last_edited);
  const bannerImages = postData.images[0]
    ? orderImages(postData.images, postData.image_order)
    : false;

  const [enlargedImage, setEnlargedImage] = useState("");
  const [showEnlargedImage, setShowEnlargedImage] = useState(false);
  const enlargeImage = (image) => {
    setEnlargedImage(image);
    setShowEnlargedImage(true);
  };

  return (
    <div className="pb-8 mx-auto lg:w-[70%] shadow-2xl">
      {showEnlargedImage && (
        <div
          className="fixed top-0 left-0 size-full z-10 p-1 bg-primary/30 flex items-center justify-center"
          onClick={() => {
            setShowEnlargedImage(false);
          }}
        >
          <img
            src={enlargedImage}
            alt={enlargedImage}
            className="size-full z-10 object-contain animate-jump-in"
          />
        </div>
      )}
      {bannerImages && (
        <Splide
          aria-label="Banner Images"
          options={{
            type: "fade",
            rewind: true,
            heightRatio: 9 / 16,
            autoplay: true,
          }}
        >
          {bannerImages.map((image, i) => {
            return (
              <SplideSlide key={i}>
                <img
                  src={image.image}
                  alt={image.image}
                  className="object-fill mx-auto h-full"
                  onClick={() => {
                    enlargeImage(image.image);
                  }}
                />
              </SplideSlide>
            );
          })}
        </Splide>
      )}
      <h1 className="flex gap-4 justify-center items-center text-3xl py-2 lg:pt-8 px-4 sm:px-8 lg:px-16 text-center">
        {postData.title}
        {postData.owner === currentUser && (
          <Link to={`/post/edit/${postData.id}`}>
            <button className="hover:text-secondary">
              <FaEdit className="min-w-9" />
            </button>
          </Link>
        )}
      </h1>
      {postData.tags && (
        <div className="flex gap-1 items-center justify-center px-4 sm:px-8 lg:px-16">
          {postData.tags.map((tag) => (
            <p
              key={tag.id}
              className="text-sm bg-primary w-fit text-white p-1 rounded-full"
            >
              #{tag.name}
            </p>
          ))}
        </div>
      )}
      {postData.authors && (
        <h2 className="text-sm text-center px-4 sm:px-8 lg:px-16">
          Author{postData.authors[1] && "s"}:{" "}
          {postData.authors.map((author) => author.full_name).join(", ")}
        </h2>
      )}
      {postData.image_authors[0] && (
        <h2 className="text-sm text-center px-4 sm:px-8 lg:px-16">
          Image Author{postData.image_authors[1] && "s"}:{" "}
          {postData.image_authors.map((author) => author.full_name).join(", ")}
        </h2>
      )}
      <h3 className="text-sm text-center lg:pb-4 px-4 sm:px-8 lg:px-16">
        Posted {stringDateCreated.date} | Last Edited{" "}
        {stringDateLastEdited.date}
      </h3>
      {/* <div
        className="postHTML py-2 px-8 lg:px-16"
        dangerouslySetInnerHTML={{
          __html: postData.content,
        }}
      /> */}
      <PostContentComponent postHtml={postData.content} isPreview={false} />
    </div>
  );
};

const Post = ({ currentUser }) => {
  const { id } = useParams();
  const [postData, setPostData] = useState({});
  const [resCode, setResCode] = useState(0);

  useEffect(() => {
    api
      .get(`/post/${id}`)
      .then((res) => {
        setPostData(res.data);
        setResCode(res.status);
      })
      .catch((err) => {
        err.status !== 404 && apiErrAlert(err);
        setResCode(err.status);
      });
  }, [id]);

  return (
    <div>
      {resCode === 200 ? (
        <PostContent postData={postData} currentUser={currentUser} />
      ) : resCode === 404 ? (
        <Page404 />
      ) : (
        <PuffLoader color="#014421" className="mx-auto my-20" size={32} />
      )}
    </div>
  );
};

export default Post;
