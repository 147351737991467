import React, { useState } from "react";
import { LuMapPin, LuPhone, LuMail } from "react-icons/lu";
import { FaFacebook } from "react-icons/fa";
import { BeatLoader } from "react-spinners";
import api, { apiErrAlert } from "../services/api";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    api
      .post("/feedbacks/", {
        name: name,
        email: email,
        contact_no: contactNo,
        content: message,
      })
      .then((res) => {
        alert("Successfully submitted form.");
        setName("");
        setEmail("");
        setContactNo("");
        setMessage("");
      })
      .catch((err) => {
        apiErrAlert(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      {/* Contact Us Section */}
      <div className="p-4 sm:p-8">
        <div className="flex items-center gap-2 sm:gap-4">
          {/* <hr className="border-secondary border-2 rounded-md flex-grow" /> */}
          <h1 className="text-4xl">Contact Us</h1>
          <hr className="border-secondary border-2 rounded-md flex-grow" />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row pt-4 pb-8 px-6 lg:px-16 justify-evenly lg:gap-8 items-center lg:items-start">
        <div className="flex flex-col items-center px-4 lg:px-0 animate-fade-up">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.3468361852083!2d125.48182186033229!3d7.085731071973847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x32f9121ec4bf7e25%3A0xe1cad0747134bec5!2sUniversity%20of%20the%20Philippines%20Mindanao!5e0!3m2!1sen!2sph!4v1727947079732!5m2!1sen!2sph"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="UP Mindanao Atrium Map"
            className="w-[300px] h-[225px] sm:w-[450px] sm:h-[340px]"
          />
          <div className="flex-col py-4 text-lg">
            <div className="flex gap-2 items-center">
              <LuMapPin className="min-w-5" />
              <p>
                Administration Building, Mintal, Davao City, 8022, Philippines
              </p>
            </div>
            <div className="flex gap-2 items-center">
              <LuPhone className="min-w-5" />
              <p>(+63 82)2930863 and (+63 82)2930185</p>
            </div>
            <div className="flex gap-2 items-center">
              <LuMail className="min-w-5" />
              <a href="mailto:qao.upmindanao@up.edu.ph">
                <p>qao.upmindanao@up.edu.ph</p>
              </a>
            </div>
            <div className="flex gap-4 p-2 text-4xl">
              <a
                href="https://www.facebook.com/qao.upmin"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook className="hover:text-blue-600 hover:cursor-pointer" />
              </a>
              {/* <FaXTwitter className="hover:text-black hover:cursor-pointer" /> */}
            </div>
          </div>
        </div>

        <form
          className="bg-white shadow-xl w-full p-4 rounded-lg flex flex-col items-center lg:max-w-[50%] animate-fade-up"
          onSubmit={handleSubmit}
        >
          <h1 className="text-center text-2xl sm:text-4xl">
            Feedback/Request Form
          </h1>
          <div className="relative z-0 my-3 w-full">
            <input
              type="text"
              id="name"
              className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-secondary peer"
              placeholder=" "
              required={true}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label
              htmlFor="name"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-secondary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
            >
              Name
            </label>
          </div>
          <div className="relative z-0 my-3 w-full">
            <input
              type="email"
              id="email"
              className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-secondary peer"
              placeholder=" "
              required={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label
              htmlFor="email"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-secondary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
            >
              Email
            </label>
          </div>
          <div className="relative z-0 my-3 w-full">
            <input
              type="tel"
              id="contactNo"
              className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-secondary peer"
              placeholder=" "
              required={true}
              value={contactNo}
              onChange={(e) => setContactNo(e.target.value)}
            />
            <label
              htmlFor="contactNo"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-secondary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
            >
              Contact Number
            </label>
          </div>
          <div className="relative z-0 my-3 w-full">
            <textarea
              id="message"
              className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-secondary peer"
              placeholder=" "
              required={true}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <label
              htmlFor="message"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-secondary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
            >
              Message
            </label>
          </div>
          <button
            type="submit"
            className="bg-primary hover:bg-secondary text-white font-bold p-4 rounded-xl disabled:bg-gray-400"
            disabled={submitting}
          >
            {submitting ? <BeatLoader className="m-auto" /> : "Submit"}
          </button>
        </form>
      </div>
    </>
  );
};

export default ContactUs;
