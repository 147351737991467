import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api, { apiErrAlert } from "../services/api";
import { PuffLoader } from "react-spinners";
import { dateToString } from "../utils/dateUtils";
import { LuMapPin, LuCalendarDays, LuClock } from "react-icons/lu";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import Page404 from "./Page404";
import { FaEdit } from "react-icons/fa";
import PostContentComponent from "../components/PostContentComponent";
import { orderImages } from "../utils/imageOrderUtils";

const EventContent = ({ eventData, currentUser }) => {
  const stringDateStart = dateToString(eventData.start_date);
  const stringDateEnd = dateToString(eventData.end_date);
  const bannerImages = eventData.images[0]
    ? orderImages(eventData.images, eventData.image_order)
    : false;

  const [enlargedImage, setEnlargedImage] = useState("");
  const [showEnlargedImage, setShowEnlargedImage] = useState(false);
  const enlargeImage = (image) => {
    setEnlargedImage(image);
    setShowEnlargedImage(true);
  };

  return (
    <div className="pb-8 mx-auto lg:w-[70%] shadow-2xl">
      {showEnlargedImage && (
        <div
          className="fixed top-0 left-0 size-full z-10 p-1 bg-primary/30 flex items-center justify-center"
          onClick={() => {
            setShowEnlargedImage(false);
          }}
        >
          <img
            src={enlargedImage}
            alt={enlargedImage}
            className="size-full z-10 object-contain animate-jump-in"
          />
        </div>
      )}
      {bannerImages && (
        <Splide
          aria-label="Banner Images"
          options={{
            type: "fade",
            rewind: true,
            heightRatio: 9 / 16,
            autoplay: true,
          }}
        >
          {bannerImages.map((image, i) => {
            return (
              <SplideSlide key={i}>
                <img
                  src={image.image}
                  alt={image.image}
                  className="object-fill mx-auto h-full"
                  onClick={() => {
                    enlargeImage(image.image);
                  }}
                />
              </SplideSlide>
            );
          })}
        </Splide>
      )}
      <h1 className="flex gap-4 justify-center items-center text-4xl py-2 lg:pt-8 px-4 sm:px-8 lg:px-16 text-center">
        {eventData.title}
        {eventData.owner === currentUser && (
          <Link to={`/event/edit/${eventData.id}`}>
            <button className="hover:text-secondary">
              <FaEdit className="min-w-9" />
            </button>
          </Link>
        )}
      </h1>
      <h2 className="text-base flex gap-1 justify-center items-center px-4 sm:px-8 lg:px-16">
        <LuMapPin /> {eventData.location}
      </h2>
      <h2 className="text-base flex gap-1 justify-center items-center px-4 sm:px-8 lg:px-16">
        <LuCalendarDays />{" "}
        {stringDateStart.date === stringDateEnd.date ? (
          `${stringDateStart.weekday}, ${stringDateStart.date}`
        ) : (
          <>
            <span className="text-center">{`${stringDateStart.weekday}, ${stringDateStart.date}`}</span>
            -
            <span className="text-center">{`${stringDateEnd.weekday}, ${stringDateEnd.date}`}</span>
          </>
        )}
      </h2>
      <h2 className="text-base flex gap-1 justify-center items-center px-4 sm:px-8 lg:px-16">
        <LuClock />{" "}
        {stringDateStart.time === stringDateEnd.time
          ? `${stringDateStart.time}`
          : `${stringDateStart.time} - ${stringDateEnd.time}`}
      </h2>
      <PostContentComponent postHtml={eventData.content} isPreview={false} />
    </div>
  );
};

const Event = ({ currentUser }) => {
  const { id } = useParams();
  const [eventData, setEventData] = useState({});
  const [resCode, setResCode] = useState(0);

  useEffect(() => {
    api
      .get(`/event/${id}`)
      .then((res) => {
        setEventData(res.data);
        setResCode(res.status);
      })
      .catch((err) => {
        err.status !== 404 && apiErrAlert(err);
        setResCode(err.status);
      });
  }, [id]);

  return (
    <div>
      {resCode === 200 ? (
        <EventContent eventData={eventData} currentUser={currentUser} />
      ) : resCode === 404 ? (
        <Page404 />
      ) : (
        <PuffLoader color="#014421" className="mx-auto my-20" size={32} />
      )}
    </div>
  );
};

export default Event;
