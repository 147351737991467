import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { PuffLoader } from "react-spinners";
import api, { apiErrAlert } from "../services/api";

import heroImage from "../assets/cover_img.jpg";
import upLogo from "../assets/UPMin_Logo.png";
import qaoLogo from "../assets/UPMin_QAO.png";
import filler from "../assets/QAO_Logo.png";
import NewsCard from "../components/NewsCard";
import EventCard from "../components/EventCard";
import { orderImages } from "../utils/imageOrderUtils";

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [postsLoading, setPostsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(true);

  const callApis = async () => {
    try {
      const resPosts = await api.get("/posts/?page_size=3");
      setPosts(resPosts.data.results);
      setPostsLoading(false);
      const resEvents = await api.get("/events/?page_size=3");
      setEvents(resEvents.data.results);
      setEventsLoading(false);
    } catch (err) {
      apiErrAlert(err);
    }
  };

  useEffect(() => {
    callApis();
  }, []);

  return (
    <div>
      {/* Hero Section */}
      <div className="h-[540px] relative flex flex-col text-white animate-fade animate-ease-in">
        <img
          src={heroImage}
          alt="Main banner"
          className="object-cover h-full w-screen absolute top-0"
        />
        <div className="h-full flex flex-col justify-center bg-gradient-to-br from-secondary/50 to-primary/50 z-10">
          <div className="flex justify-evenly mb-8">
            <img
              src={upLogo}
              className="h-[128px] sm:h-[192px]"
              alt="UPMin Logo"
            />
            <img
              src={qaoLogo}
              className="h-[128px] sm:h-[192px] rounded-full"
              alt="UPMin QAO Logo"
            />
          </div>
          <h3 className="text-lg sm:text-2xl text-center">
            University of the Philippines Mindanao
          </h3>
          <h1 className="text-4xl sm:text-6xl text-center font-bold">
            Quality Assurance Office
          </h1>
        </div>
      </div>

      {/* News Section */}
      <div className="p-4 sm:p-8">
        <div className="flex items-center gap-2 sm:gap-4 pb-4">
          <h1 className="text-4xl">Latest News</h1>
          <hr className="border-secondary border-2 rounded-md flex-grow" />
        </div>
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
          <PuffLoader
            loading={postsLoading}
            color="#014421"
            className="m-auto"
            size={32}
          />
          {posts.map((post) => {
            return (
              <NewsCard
                key={post.id}
                targetPage={`/post/${post.id}`}
                bannerImg={
                  post.images[0]
                    ? orderImages(post.images, post.image_order)[0].image
                    : filler
                }
                title={post.title}
                author={post.authors
                  .map((author) => author.full_name)
                  .join(", ")}
                content={post.content}
                date={post.created}
              />
            );
          })}
          <div className="flex items-center justify-center">
            <Link
              to={`/news`}
              className="bg-primary px-4 py-2 text-white text-2xl rounded-full m-2 shadow-xl hover:bg-secondary hover:scale-105 duration-300 w-fit h-fit"
            >
              More news <FaAngleRight className="min-h-4 inline" />
            </Link>
          </div>
        </div>
      </div>

      {/* Events Section */}
      <div className="p-4 sm:p-8">
        <div className="flex items-center gap-2 sm:gap-4 pb-4">
          <h1 className="text-4xl">Latest Events</h1>
          <hr className="border-secondary border-2 rounded-md flex-grow" />
        </div>
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
          <PuffLoader
            loading={eventsLoading}
            color="#014421"
            className="m-auto"
            size={32}
          />
          {events.map((event) => {
            return (
              <EventCard
                key={event.id}
                targetPage={`/event/${event.id}`}
                bannerImg={
                  event.images[0]
                    ? orderImages(event.images, event.image_order)[0].image
                    : filler
                }
                startDate={event.start_date}
                endDate={event.end_date}
                eventName={event.title}
                location={event.location}
              />
            );
          })}
          <div className="flex items-center justify-center">
            <Link
              to={`/events`}
              className="bg-primary px-4 py-2 text-white text-2xl rounded-full m-2 shadow-xl hover:bg-secondary hover:scale-105 duration-300 w-fit h-fit"
            >
              More events <FaAngleRight className="min-h-4 inline" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
