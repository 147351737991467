import React from "react";
import { Link } from "react-router-dom";
import { dateToString } from "../utils/dateUtils";
import PostContentComponent from "./PostContentComponent";
import { LuBookOpenCheck, LuBookX } from "react-icons/lu";

const NewsCard = ({
  targetPage,
  bannerImg,
  title,
  author,
  content,
  date,
  is_published = null,
}) => {
  const stringDateCreated = dateToString(date);

  return (
    <Link
      to={targetPage}
      className="bg-white shadow-xl rounded-xl overflow-hidden hover:-mt-5 hover:mb-5 duration-300 w-full animate-jump-in"
    >
      <div className="flex flex-col h-full">
        <img
          src={bannerImg}
          alt={bannerImg.split("/").pop()}
          className="object-cover w-full h-[256px]"
        />
        <h1 className="px-2 mt-2 text-3xl line-clamp-2" title={title}>
          {title}
        </h1>
        <h2 className="px-2 mb-2 text-base text-gray-600">By: {author}</h2>
        <PostContentComponent postHtml={content} isPreview={true} />
        <div className="flex justify-between mt-auto">
          <p className="p-2 text-gray-400">
            {`${stringDateCreated.weekday}, ${stringDateCreated.date}`}
          </p>
          {is_published === true ? (
            <div className="flex items-center gap-1 p-2 text-secondary">
              <LuBookOpenCheck /> <p>PUBLISHED</p>
            </div>
          ) : (
            is_published === false && (
              <div className="flex items-center gap-1 p-2 text-primary">
                <LuBookX /> <p>UNPUBLISHED</p>
              </div>
            )
          )}
        </div>
      </div>
    </Link>
  );
};

export default NewsCard;
