import React from "react";

import heroImage from "../assets/qao_door.jpg";
import aabuenaobra from "../assets/staff-img/aabuenaobra.jpg";
import rlyu from "../assets/staff-img/rlyu.jpg";
import capoledo from "../assets/staff-img/capoledo.jpg";
import tgang from "../assets/staff-img/tgang.jpg";
import rtgamot from "../assets/staff-img/rtgamot.jpg";
import qualityPolicy from "../assets/up_quality_policy.png";

const AboutUs = () => {
  return (
    <>
      {/* Hero Section */}
      <div className="relative h-[540px] flex flex-col shadow-xl animate-fade">
        <img
          src={heroImage}
          alt="Door to QAO"
          className="object-cover h-full w-screen absolute top-0"
        />
        <div className="h-full bg-gradient-to-br to-secondary/30 from-primary/30 z-10" />
      </div>
      <div className="mx-auto lg:w-[70%] shadow-2xl">
        <img
          src={qualityPolicy}
          alt="UP Mindanao Quality Policy"
          className="p-4 sm:p-8"
        />

        {/* About UP QAO */}
        <div className="p-4 sm:p-8">
          <div className="flex items-center gap-2 sm:gap-4">
            <hr className="border-secondary border-2 rounded-md flex-grow" />
            <h1 className="text-2xl text-center w-60 sm:w-auto sm:text-3xl">
              UP Mindanao Quality Assurance Office
            </h1>
            <hr className="border-secondary border-2 rounded-md flex-grow" />
          </div>
          <p className="pt-1">
            UP Mindanao is the only UP constituent university in Mindanao with
            the primary mandate to provide equitable access to quality UP
            education to Mindanaoans. It offers academic programs in the fields
            of the humanities, architecture, mathematics, food, economics,
            social, natural, and computing sciences. As a graduate university,
            it offers PhD by Research, and graduate degrees in management, food
            science, urban and regional planning, and sports science. Its
            development agenda focuses on opening additional academic programs
            and new colleges in human kinetics, medicine, and engineering, to
            support Mindanao sports, health, technological, and bio-cultural
            diversity initiatives. With these, it aims to be an internationally
            competitive graduate, research, and public service university,
            primed to contribute to Philippine development.
          </p>
        </div>

        {/* Vision*/}
        <div className="p-4 sm:p-8">
          <div className="flex items-center gap-2 sm:gap-4">
            <hr className="border-secondary border-2 rounded-md flex-grow" />
            <h1 className="text-3xl text-center">Vision</h1>
            <hr className="border-secondary border-2 rounded-md flex-grow" />
          </div>
          <p className="pt-1">
            A transformative university at the forefront of 21st-century
            innovation in education, research, creative works, and public
            service by 2025.
          </p>
        </div>

        {/* Mission*/}
        <div className="p-4 sm:p-8">
          <div className="flex items-center gap-2 sm:gap-4">
            <hr className="border-secondary border-2 rounded-md flex-grow" />
            <h1 className="text-3xl text-center">Mission</h1>
            <hr className="border-secondary border-2 rounded-md flex-grow" />
          </div>
          <p className="pt-1">
            Our mission is to provide education, research capability, creative
            environment, and public engagement that transform lifelong learners
            into responsive leaders advancing culturally sensitive solutions to
            issues of rapidly changing times.
          </p>
        </div>

        {/* The Team */}
        <div className="p-4 sm:p-8">
          <div className="flex items-center gap-2 sm:gap-4">
            <hr className="border-secondary border-2 rounded-md flex-grow" />
            <h1 className="text-3xl text-center">The Team</h1>
            <hr className="border-secondary border-2 rounded-md flex-grow" />
          </div>
          {/* Pictures */}
          <div className="flex flex-col items-center p-4 animate-fade-up">
            <img
              src={rtgamot}
              alt="A/Prof. Ritchie Mae T. Gamot, PhD"
              className="size-[192px] lg:size-[256px] rounded-full object-cover shadow-2xl outline outline-secondary"
            />
            <h1 className="font-bold text-center text-lg pt-2">
              A/Prof. Ritchie Mae T. Gamot, PhD
            </h1>
            <h2 className="text-center text-lg">QAO Director</h2>
          </div>
          <div className="flex flex-col items-center p-4 animate-fade-up">
            <img
              src={tgang}
              alt="Theressa G. Ang"
              className="size-[128px] lg:size-[192px] rounded-full object-cover shadow-2xl outline outline-secondary"
            />
            <h1 className="font-bold text-center text-lg pt-2">
              Theressa G. Ang
            </h1>
            <h2 className="text-center text-lg">Administrative Officer 1</h2>
          </div>
          <div className="sm:flex justify-evenly">
            <div className="flex flex-col items-center p-4 animate-fade-up">
              <img
                src={rlyu}
                alt="Rozhelle Sophia L. Yu"
                className="size-[128px] lg:size-[192px] rounded-full object-cover shadow-2xl outline outline-secondary"
              />
              <h1 className="font-bold text-center text-lg pt-2">
                Rozhelle Sophia L. Yu
              </h1>
              <h2 className="text-center text-lg">Senior Office Aide</h2>
            </div>
            <div className="flex flex-col items-center p-4 animate-fade-up">
              <img
                src={aabuenaobra}
                alt="Andrea Trisha A. Buenaobra"
                className="size-[128px] lg:size-[192px] rounded-full object-cover shadow-2xl outline outline-secondary"
              />
              <h1 className="font-bold text-center text-lg pt-2">
                Andrea Trisha A. Buenaobra
              </h1>
              <h2 className="text-center text-lg">Senior Office Aide</h2>
            </div>
          </div>
          <div className="flex flex-col items-center p-4 animate-fade-up">
            <img
              src={capoledo}
              alt="Clent Japhet A. Poledo"
              className="size-[128px] lg:size-[192px] rounded-full object-cover shadow-2xl outline outline-secondary"
            />
            <h1 className="font-bold text-center text-lg pt-2">
              Clent Japhet A. Poledo
            </h1>
            <h2 className="text-center text-lg">Junior ICT Assistant</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
